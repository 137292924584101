import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/reducers/modalReducer";
import { IoIosArrowRoundUp } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";

const MobileCTA = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="mobile-view-contact">
      <div className="sticky-tool">
        <div className="arrow-btn">
          <IoIosArrowRoundUp
            size={34}
            onClick={() => setIsOpen(!isOpen)}
            className={!isOpen ? "arrow-icon" : ""}
            style={{ transition: "transform 0.3s ease" }}
          />
        </div>
        <div
          className="whatsapp-btn"
          style={{
            transform: isOpen ? "translateY(100px)" : "translateY(0px)",
            opacity: isOpen ? "0" : "1",
            transition: "transform 0.3s ease-out",
          }}
        >
          <RiWhatsappFill
            size={50}
            className="whatsapp"
            onClick={() => {
              window.open("https://wa.me/+918957741152");
            }}
          />
        </div>
        <div
          className="call-btn"
          style={{
            transform: isOpen ? "translateY(100px)" : "translateY(0)",
            opacity: isOpen ? "0" : "1",
            transition: "transform 0.3s ease",
          }}
        >
          <a href="tel:+918957741152" className="menu-number">
            <IoCallOutline size={50} className="whatsapp" />
          </a>
        </div>
        <a
          className="booking-btn-mobile-view"
          style={{
            transform: isOpen ? "translateY(100px)" : "translateY(0)",
            opacity: isOpen ? "0" : "1",
            transition: "transform 0.3s ease",
          }}
          onClick={() => dispatch(openModal())}
        >
          Book Now
        </a>
      </div>
    </div>
  );
};

export default MobileCTA;
