import React from "react";
import { RiWhatsappFill } from "react-icons/ri";

const WhatsApp = () => {
  return (
    <div className="floating-wp">
      <RiWhatsappFill
        size={50}
        className="floating-cursor"
        onClick={() => {
          window.open("https://wa.me/+918957741152");
        }}
      />
    </div>
  );
};

export default WhatsApp;
