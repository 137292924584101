import React from "react";
import "./input.css";

const Input = ({
  placeholder,
  type = "text",
  register,
  error,
  helperText,
  ...props
}) => {
  return (
    <div>
      <input {...props} type={type} placeholder={placeholder} {...register} />
      {((error && error.message) || helperText) && (
        <span
          style={{
            color: error ? "#f6416c" : "grey",
            fontSize: "14px",
            margin: "2px",
          }}
        >
          {(error && error.message) || helperText}
        </span>
      )}
    </div>
  );
};

export default Input;
