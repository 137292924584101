import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoCheckmarkSharp } from "react-icons/io5";
import Slider1 from "../../assets/images/hotel.webp";
import Slider2 from "../../assets/images/bg-room.webp";
import Slider3 from "../../assets/images/bg.webp";
import Slider4 from "../../assets/images/about-slide.webp";
import Slider5 from "../../assets/images/about-slider2 .webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import Modal from "../modal/Modal";
import Input from "../input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  setHasShownModal,
} from "../../redux/reducers/modalReducer";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { sendMessage } from "../../api";
import LoaderScreen from "../LoaderScreen";
import { useNavigate } from "react-router-dom";
import { ROUTE_THANK_YOU } from "../../constants";

const EnquireForm = () => {
  const dispatch = useDispatch();
  const { isModalOpen, hasShownModal } = useSelector((state) => state.modal);
  const images = [Slider1, Slider2, Slider3, Slider4, Slider5];
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      adultCount: 1,
      childCount: 0,
      rooms: 1,
      checkInDate: null,
      checkOutDate: null,
      message: "",
    },
  });

  const checkInDate = watch("checkInDate");
  const checkOutDate = watch("checkOutDate");

  useEffect(() => {
    const modalShown = localStorage.getItem("hasShownModal") === "true";
    if (modalShown && !hasShownModal) {
      dispatch(setHasShownModal(true));
    }
  }, [dispatch, hasShownModal]);

  useEffect(() => {
    if (!hasShownModal && localStorage.getItem("hasShownModal") !== "true") {
      const timer = setTimeout(() => {
        dispatch(openModal());
        dispatch(setHasShownModal(true));
        localStorage.setItem("hasShownModal", "true");
      }, 45000);

      return () => clearTimeout(timer);
    }
  }, [hasShownModal, dispatch]);

  const handleCloseModal = () => {
    dispatch(closeModal());
    dispatch(setHasShownModal(true));
    localStorage.setItem("hasShownModal", "true");

    reset();
  };

  const handleManualOpen = () => {
    dispatch(openModal());
    dispatch(setHasShownModal(true));
    localStorage.setItem("hasShownModal", "true");
  };

  const onSubmit = (data) => {
    const checkInDate = data.checkInDate?.toLocaleDateString("en-gb");
    const checkOutDate = data.checkOutDate.toLocaleDateString("en-gb");
    const formattedData = {
      name: data.name,
      email: data.email,
      phone: data.phone.toString(),
      subject: "BOOKING REQUEST FROM WEBSITE",
      message: `Adults: ${data?.adultCount?.toString() || 0} 
      \n , Children: ${data?.childCount?.toString() || 0} \n , Rooms :${
        data?.rooms
      } \n Message: ${data.message}`,
      check_in: data.checkInDate ? checkInDate : "",
      check_out: data.checkOutDate ? checkOutDate : "",
    };
    setLoading(true);
    sendMessage(formattedData)
    toast.success("Message Sent Successfully");
    navigate(ROUTE_THANK_YOU, { state: data });
    reset();
    setLoading(false);
    dispatch(closeModal());
  };

  return (
    <div>
      {isLoading && <LoaderScreen />}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="check-in-checkout-container">
          <h2 className="info-form-title">Enquire Form</h2>
          <div className="overflow-control">
            <div className="form-field-wrap">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group">
                    <Input
                      register={register("name", {
                        required: "*Please enter name",
                      })}
                      placeholder="Name"
                      className="loginField"
                      error={errors.name}
                    />
                  </div>

                  <div className="form-group">
                    <Input
                      register={register("phone", {
                        required: "*Please enter phone",
                      })}
                      type="number"
                      placeholder="Phone No"
                      className="loginField no-spinner"
                      error={errors.phone}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <Input
                    register={register("email", {
                      required: "*Please enter email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "*Please enter a valid email",
                      },
                    })}
                    placeholder="Email"
                    className="loginField"
                    error={errors.email}
                  />
                </div>

                <div className="guest-details">
                  <div className="form-row" style={{ paddingTop: "0px" }}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="adultCount">
                        Number of Adults
                      </label>
                      <Input
                        register={register("adultCount", {
                          required: "*Please enter number of adults",
                          valueAsNumber: true,
                          min: {
                            value: 1,
                            message: "*Minimum 1 adult required",
                          },
                        })}
                        type="number"
                        id="adultCount"
                        className="loginField"
                        min="1"
                        error={errors.adultCount}
                      />
                    </div>

                    <div className="form-group">
                      <labe className="form-label" htmlFor="childCount">
                        Number of Children
                      </labe>
                      <Input
                        register={register("childCount", {
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message: "*Cannot be negative",
                          },
                        })}
                        type="number"
                        id="childCount"
                        className="loginField no-spinner"
                        min="0"
                      />
                      <small
                        className="form-notice"
                        style={{ fontSize: "11px" }}
                      >
                        Children must be 6 years or younger
                      </small>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="childCount">
                        Number of Rooms
                      </label>
                      <Input
                        register={register("rooms", {
                          required: "*Please enter number of rooms",
                          valueAsNumber: true,
                          min: {
                            value: 1,
                            message: "*Minimum 1 Room required",
                          },
                        })}
                        type="number"
                        id="rooms"
                        className="loginField"
                        min="1"
                        error={errors.rooms}
                      />
                    </div>
                  </div>
                </div>

                <div className="date-pickers">
                  <div className="form-group">
                    <label className="form-label" htmlFor="checkInDate">
                      Check-in Date
                    </label>
                    <Controller
                      control={control}
                      name="checkInDate"
                      rules={{ required: "*Please select check-in date" }}
                      render={({ field }) => (
                        <DatePicker
                          id="checkInDate"
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            if (date) {
                              const nextDay = new Date(date);
                              nextDay.setDate(nextDay.getDate() + 1);
                              setValue("checkOutDate", nextDay);
                            }
                          }}
                          selectsStart
                          startDate={field.value}
                          endDate={checkOutDate}
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy"
                          placeholderText="Select check-in date"
                          className="date-picker"
                        />
                      )}
                    />
                    {errors.checkInDate && (
                      <span className="error-message">
                        {errors.checkInDate.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="checkOutDate">
                      Check-out Date
                    </label>
                    <Controller
                      control={control}
                      name="checkOutDate"
                      rules={{ required: "*Please select check-out date" }}
                      render={({ field }) => (
                        <DatePicker
                          id="checkOutDate"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          selectsEnd
                          startDate={checkInDate}
                          endDate={field.value}
                          minDate={
                            checkInDate
                              ? new Date(checkInDate.getTime() + 86400000)
                              : new Date()
                          }
                          dateFormat="MMMM d, yyyy"
                          placeholderText="Select check-out date"
                          className="date-picker"
                          disabled={!checkInDate}
                        />
                      )}
                    />

                    {errors.checkOutDate && (
                      <span className="error-message">
                        {errors.checkOutDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "12px" }}>
                  <textarea
                    rows={4}
                    placeholder="Message"
                    className="loginField"
                    {...register("message", {
                      required: "*Please enter message",
                    })}
                  />
                  {errors && errors.message && (
                    <span
                      style={{
                        color: errors.message ? "#f6416c" : "grey",
                        fontSize: "14px",
                        margin: "2px",
                      }}
                    >
                      {errors && errors.message.message}
                    </span>
                  )}
                </div>
                <button type="submit" className="send-btn" disabled={isLoading}>
                  {isLoading ? "Sending..." : "Enquire"}
                </button>
              </form>
            </div>
            <div className="benefits-wrap">
              <h3 className="benefits-heading">
                The benefits of direct booking
              </h3>
              <li className="benefits-list">
                <IoCheckmarkSharp size={16} /> Best Rate Guaranteed
              </li>
              <li className="benefits-list">
                <IoCheckmarkSharp size={16} />
                Direct Reservation With Hotel, No Booking Fees
              </li>
              <li className="benefits-list">
                <IoCheckmarkSharp size={16} />
                Free Cancellation Subject to Rate Policy
              </li>
              <li className="benefits-list">
                <IoCheckmarkSharp size={16} />
                Exclusive Offers & Benefits
              </li>
              <div className="sliderMain mobile-responsive">
                <Swiper
                  spaceBetween={0}
                  speed={1000}
                  slidesPerView={1}
                  style={{
                    "--swiper-pagination-color": "#790316",
                    height: "150px",
                  }}
                  className="slide-width"
                  pagination={{
                    clickable: true,
                    bulletClass: "swiper-pagination-bullet",
                    renderBullet: (index, className) => {
                      return ` <span class="${className} feature-pagination"></span>`;
                    },
                  }}
                  modules={[Pagination, Autoplay]}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                >
                  {images.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          src={item}
                          className="slider-image"
                          alt={`Slide ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EnquireForm;
