import React from "react";
import "./index.css";
import Layout from "../../layout";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";

const Thankyou = () => {
  const location = useLocation();
  return (
    <Layout>
      <div className="thank-you-container">
        <IoMdCheckmarkCircleOutline
          color="#790316"
          className="IoMdCheckmarkCircleOutline"
        />
        <h1 className="thank-you-greet">
          Thank you for choosing our hotel,{" "}
          <span className="thank-you-greet-name">
            {location?.state?.name}!{" "}
          </span>{" "}
        </h1>
        <div className="thank-you-greet-description">
          For immediate assistance, you can call our reservation desk at
          <a
            style={{ cursor: "pointer", padding: "0px 8px", color: "#790316" }}
            href="tel:+918957741152"
          >
            +91- 8957741152
          </a>{" "}
          (available 24/7).
          <br /> Our team will contact you within 24 hours to confirm your
          booking details.
          <br />{" "}
        </div>
        <p className="welcoming-label">
          We look forward to welcoming you soon!{" "}
        </p>
        {/* <div>
          <a href="tel:+918957741152" className="thank-you-call-btn">
       
            CALL NOW
          </a>
        </div> */}
      </div>
    </Layout>
  );
};

export default Thankyou;
