import React, { useState } from "react";
import "./header.css";
import FullLogo from "../../assets/images/bg-logo.png";
import { BOOKING_LINK, ROUTE_CONTACT, WHATS_APP, rooms } from "../../constants";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import ClickAwayListener from "react-click-away-listener";
import { IoCallOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "../sidebar/Sidebar";
import LogoHorizontal from "../../assets/images/hotel-ananda-kashi.png";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/reducers/modalReducer";

const Header = () => {
  const navigate = useNavigate();
  const [IsActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="header-container">
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="logo-wrapper">
        <a href={"/"}>
          <img src={FullLogo} alt="Hotel Ananda Kashi" className="full-logo" />
        </a>
      </div>
      <div className="logo-mobile-wrapper">
        <a href={"/"}>
          <img
            src={FullLogo}
            alt="Hotel Ananda Kashi"
            className="full-logo"
            onClick={() => navigate("/")}
          />
          <img
            src={LogoHorizontal}
            alt="Hotel Ananda Kashi"
            className="LogoHorizontal-logo"
          />
        </a>
        <div className="burger-wrapper">
          <li className="menu-list-contact">
            <IoCallOutline className="IoCallOutline" />
            <div>
              <a href="tel:+918957741152" className="menu-number">
                +91- 8957741152
              </a>
              <p className="menu-number-label">Call us to Book now</p>
            </div>
          </li>
          <li className="menu-list-btn" onClick={() => dispatch(openModal())}>
            <a className="booking-mobile-btn" target="_blank">
              Book Now
            </a>
          </li>
          <GiHamburgerMenu
            size={"2rem"}
            color="#fff"
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
      <div className="header-menuList">
        <li className="menu-list">
          <a href={"/"} className="menu-list-a">
            Home
          </a>
        </li>
        <li className="menu-list">
          <a href={"/about"} className="menu-list-a">
            About Us
          </a>
        </li>
        <li className="menu-list-rooms">
          <div
            style={{ display: "flex", alignItems: "center", gap: ".2rem" }}
            onMouseEnter={() => setIsActive(true)}
          >
            Rooms
            <IoMdArrowDropdown size={25} color="#fff" />
          </div>
          {IsActive ? (
            <ClickAwayListener onClickAway={() => setIsActive(false)}>
              <div
                className="room-dropDown-wrap"
                onMouseLeave={() => setIsActive(false)}
              >
                {rooms?.map((r, i) => {
                  return (
                    <div
                      className="room-dropDown-title"
                      onClick={() => {
                        navigate(`/room/${r.key}`, { state: r });
                        setIsActive(false);
                      }}
                      key={i}
                    >
                      {r.title}
                    </div>
                  );
                })}
              </div>
            </ClickAwayListener>
          ) : (
            ""
          )}
        </li>
        <li className="menu-list">
          <a href={"/tourists_attractions"} className="menu-list-a">
            Attractions
          </a>
        </li>
        <li className="menu-list">
          <a href={`/${ROUTE_CONTACT}`} className="menu-list-a">
            Contact
          </a>
        </li>
        <li
          className="menu-list-contact"
          onClick={() => {
            window.open(WHATS_APP, "_blank");
          }}
        >
          <IoCallOutline size={"2rem"} />
          <div>
            <p className="menu-number">+91- 8957741152</p>
            <p className="menu-number-label">Call us to Book now</p>
          </div>
        </li>
        <li className="menu-list-btn" onClick={() => dispatch(openModal())}>
          <a className="booking-btn">Book Now</a>
        </li>
      </div>
    </div>
  );
};

export default Header;
