import logo from "../../assets/images/bg-logo.png";
import { BiSolidMap } from "react-icons/bi";
import { IoMdMail } from "react-icons/io";
import { BiSolidPhone } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import "./footer.css";
import {
  FACEBOOK_LINK,
  GOOGLE_LOCATION,
  INSTA_LINK,
  ROUTE_CONTACT,
  ROUTE_TERMS_CONDITIONS,
  TWITTER,
  WHATS_APP,
  rooms,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { RiWhatsappFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  const navigate = useNavigate();
  const quickLinksList = [
    { label: "Home", href: "/" },
    { label: "About Us", href: "/about" },
    { label: "Attractions", href: "/tourists_attractions" },
    { label: "Contact Us", href: `/${ROUTE_CONTACT}` },
    {
      label: "Terms & Conditions",
      href: `/${ROUTE_TERMS_CONDITIONS}`,
    },
  ];

  const date = new Date();

  return (
    <footer>
      <div className="footer">
        <div className="footer-section">
          <div className="footer-block">
            <img src={logo} alt="Hotel Ananda kashi" />
            <p>
              Hotel Ananda Kashi is one of the best places where hospitality and
              traditions converge in Varanasi City. Situated at Madhyameshwar,
              Maidagin road, conveniently located for all business class,
              leisure and pilgrim travelers, within WALKING distance FROM ALL
              MAJOR TEMPLES AND GHATS
            </p>
            <div className="footer-social">
              <FaFacebook
                size={27}
                className="cursor"
                onClick={() => {
                  window.open(FACEBOOK_LINK, "_blank");
                }}
              />
              <AiFillInstagram
                size={30}
                className="cursor"
                onClick={() => {
                  window.open(INSTA_LINK, "_blank");
                }}
              />

              <FaSquareXTwitter
                size={30}
                className="cursor"
                onClick={() => {
                  window.open(TWITTER, "_blank");
                }}
              />
              <RiWhatsappFill
                size={30}
                className="cursor"
                onClick={() => {
                  window.open("https://wa.me/+918957741152");
                }}
              />
            </div>
          </div>

          <div className="footer-block">
            <h2 className="footer-title">Quick Links</h2>
            {quickLinksList.map((link, a) => (
              <li key={a}>
                <a href={link.href}>{link.label}</a>
              </li>
            ))}
          </div>

          <div className="footer-block">
            <h2 className="footer-title">Rooms & Suites</h2>
            {rooms?.map((link, a) => (
              <li
                key={a}
                className="room-cat"
                onClick={() => {
                  navigate(`/room/${link.key}`, { state: link });
                }}
              >
                {link.key}
              </li>
            ))}
          </div>

          <div className="footer-block">
            <h2 className="footer-title">Contact Us</h2>
            <li>
              <span className="icon">
                <BiSolidMap />
              </span>
              <span className="contact-value">
                <a href={GOOGLE_LOCATION} target="_blank">
                  K-53/48-5 Madhyameshwar Maidagin Varanasi, UP-221001
                </a>
              </span>
            </li>
            <li>
              <span className="icon">
                <IoMdMail />{" "}
              </span>
              <span className="contact-value">
                <a href="mailto:hotelanandakashi@gmail.com">
                  hotelanandakashi@gmail.com
                </a>
              </span>
            </li>
            <li>
              <span className="icon">
                <BiSolidPhone />
              </span>
              <span className="contact-value">+918957741152</span>
            </li>
          </div>
        </div>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            padding: "5px 10px",
          }}
          title="Go to top"
          className="send-btn"
        >
          &#10548;
        </button>
      </div>
      <div className="developer-block">
        © {date.getFullYear()} Hotel Ananda Kashi. All Rights Reserved. Designed
        By{" "}
        <span
          className="dev"
          onClick={() => {
            window.open("https://github.com/avtarvikas", "_blank");
          }}
        >
          avtarvikas
        </span>
      </div>
    </footer>
  );
};

export default Footer;
