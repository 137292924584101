import KashiSlide1 from "./assets/images/kashi-v-temple/kt.webp";
import KashiSlide2 from "./assets/images/kashi-v-temple/kashi2.webp";
import KashiSlide3 from "./assets/images/kashi-v-temple/kashi3.webp";
import KaalSlide3 from "./assets/images/kaal-bhairav/kaal3.webp";
import KaalSlide1 from "./assets/images/kaal-bhairav/kaal2.webp";
import KaalSlide2 from "./assets/images/kaal-bhairav/kaalBhairav3.webp";
import Dasaswamedh1 from "./assets/images/dasaswamedh/dasha-ghat1.webp";
import Dasaswamedh2 from "./assets/images/dasaswamedh/dasha-ghat2.webp";
import Dasaswamedh3 from "./assets/images/dasaswamedh/dasha-ghat3.webp";
import ManiGhat1 from "./assets/images/mani-ghat/mani1.webp";
import ManiGhat2 from "./assets/images/mani-ghat/mani2.webp";
import ManiGhat3 from "./assets/images/mani-ghat/mani3 .webp";
import AssiGhat1 from "./assets/images/assi-ghat/assi1 .webp";
import AssiGhat2 from "./assets/images/assi-ghat/assi2 .webp";
import AssiGhat3 from "./assets/images/assi-ghat/assi3 .webp";
import SankatMochan1 from "./assets/images/sankat-mochan/Sankat-M2.webp";
import SankatMochan2 from "./assets/images/sankat-mochan/sankat-m3.webp";
import SankatMochan3 from "./assets/images/sankat-mochan/sankat-m1.webp";
import BHU1 from "./assets/images/bhu/bhu1.webp";
import BHU2 from "./assets/images/bhu/bhu2.webp";
import BHU3 from "./assets/images/bhu/bhu3 .webp";
import TulsiTemple1 from "./assets/images/tulsimanas/tulsi.webp";
import TulsiTemple2 from "./assets/images/tulsimanas/tulsi2.webp";
import TulsiTemple3 from "./assets/images/tulsimanas/tulsi3.webp";
import DurgaTemple1 from "./assets/images/durgamatatemple/durgatemple.webp";
import DurgaTemple2 from "./assets/images/durgamatatemple/durgaTemple2.webp";
import DurgaTemple3 from "./assets/images/durgamatatemple/durgaTemple3.webp";
import Sarnath1 from "./assets/images/saranath/sarnath.webp";
import Sarnath2 from "./assets/images/saranath/saranath2.webp";
import Sarnath3 from "./assets/images/saranath/sarnath3.webp";
import RamNagarFort1 from "./assets/images/ramNagar/ramnagar1.webp";
import RamNagarFort2 from "./assets/images/ramNagar/ramanagar2.webp";
import RamNagarFort3 from "./assets/images/ramNagar/ramnagar3.webp";
import SuiteBedRoom from "./assets/images/suite/2.webp";
import ExecutiveBathroom3 from "./assets/images/Executive/6.webp";
import StudioBedRoom from "./assets/images/Studio/1.webp";
import SuiteRoom from "./assets/images/suite/1.webp";
import SuiteRoom2 from "./assets/images/suite/2.webp";
import SuiteRoom3 from "./assets/images/suite/3.webp";
import SuiteRoom4 from "./assets/images/suite/4.webp";
import ExecutiveRoom from "./assets/images/Executive/1.webp";
import ExecutiveRoom2 from "./assets/images/Executive/2.webp";
import ExecutiveRoom3 from "./assets/images/Executive/3.webp";
import ExecutiveRoom4 from "./assets/images/Executive/4.webp";
import ExecutiveRoom5 from "./assets/images/Executive/5.webp";
import ExecutiveRoom6 from "./assets/images/Executive/6.webp";
import StudioRoom from "./assets/images/Studio/1.webp";
import StudioRoom2 from "./assets/images/Studio/2.webp";
import StudioRoom3 from "./assets/images/Studio/3.webp";
import StudioRoom4 from "./assets/images/Studio/4.webp";
import StudioRoom5 from "./assets/images/Studio/5.webp";
import StudioRoom6 from "./assets/images/Studio/6.webp";

export const BOOKING_LINK =
  "https://www.booking.com/hotel/in/ananda-kashi.en-gb.html?label=gen173nr-1FCAsobEIMYW5hbmRhLWthc2hpSDNYBGhsiAEBmAEJuAEYyAEM2AEB6AEB-AEDiAIBqAIEuAK_vtmpBsACAdICJDUzZTFlZjg5LWM2OTQtNDM1Ni04YTBjLWQ5NjZiMzM2ODhiYdgCBeACAQ&sid=6047fe25fe1951ee60fd203c2d5b4939&dist=0&keep_landing=1&sb_price_type=total&type=total&";

export const GOOGLE_LOCATION =
  "https://www.google.com/maps/place/Hotel+Ananda+Kashi/@25.3205128,83.0140288,17z/data=!3m1!4b1!4m6!3m5!1s0x398e2f3f1a1b3847:0xe2580a7583896894!8m2!3d25.3205128!4d83.0140288!16s%2Fg%2F11l5zdc80m?hl=en-IN&entry=ttu";

export const INSTA_LINK = "https://www.instagram.com/hotelanandakashi/";

export const FACEBOOK_LINK =
  "https://www.facebook.com/profile.php?id=61553556374650";
export const WHATS_APP = "https://web.whatsapp.com/";
export const TWITTER = "https://twitter.com/Hotelananda";

export const KAL_BHAIRAV =
  "https://en.wikipedia.org/wiki/Kaal_Bhairav_Mandir,_Varanasi";

export const VISHWANATH_TEMPLE =
  "https://en.wikipedia.org/wiki/Kashi_Vishwanath_Temple";

export const MRITUNJAYA_TEMPLE =
  "https://en.wikipedia.org/wiki/Mrityunjay_Mahadev_Mandir";

export const NAMO_GHAT =
  "https://www.google.com/maps/search/Namo+ghat+varanasi/@25.3251994,83.0304972,16z/data=!3m1!4b1?entry=ttu";

export const HOME_ABOUT_US =
  "Situated at Madhyameshwar, in the heart of the city, HOTEL ANANDA KASHI strives to offer a comfortable and peaceful stay, while staying right next to the main attractions of the city.Ideal for both pilgrims and corporates- the hotel is 400mtr from the no-vehicle zone to Kashi Vishwanath temple while the wholesale markets are on the other side. Your comfort is our goal- our team endeavours to provide the best possible service so as to make your stay pleasant and memorable, and experience Ananda Kashi. We believe it’s the small things that matter- we make it our duty to cater to the smallest of your needs and requests to the best of our abilities.";

export const STUDIO_CONTENT =
  "Experience comfort and functionality in our thoughtfully designed Studio Room. This compact yet inviting space is equipped with a comfortable king-sized bed and a functional work desk. Enjoy modern amenities, including a flat-screen TV and high-speed Wi-Fi. The en-suite bathroom features a refreshing shower and complimentary toiletries. With its smart layout and stylish decor, our Studio Room is perfect for both business travelers and leisure seekers.";

export const EXECUTIVE_CONTENT =
  "Indulge in luxury and comfort with our Executive Rooms, meticulously designed to cater to the discerning traveler. Whether you're here for business or leisure, this elegant space offers a sanctuary of style and sophistication.";

export const SUITE_CONTENT =
  " The suite rooms at Hotel Ananda Kashi are designed to give our guests a luxurious stay with modern facilities. The 430 sq.ft room offer a tranquil ambience. Best suited for guests who love luxury. This spacious sanctuary combines refined elegance with modern amenities, offering a private oasis for the discerning traveler. Unwind in style and elevate your stay to an extraordinary level of comfort.";

export const ROUTE_CONTACT = "contact-us";
export const ROUTE_ABOUT = "about";
export const ROUTE_TERMS_CONDITIONS = "terms-conditions";
export const ROUTE_THANK_YOU = "thank-you";

export const emailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const attractionsData = [
  {
    title: "KASHI VISHWANATH TEMPLE",
    img: [KashiSlide1, KashiSlide2, KashiSlide3],
    details:
      "Kashi Vishwanath temple is an important temple located in Varanasi. It is a famous shrine dedicated to Lord Shiva, Hindu God. It is one of the 12 holiest Shiva temples termed as Jyoti lingas. Varanasi is also called as Kashi and Shiva is considered as the ruler of the universe. Vishwanath refers to the term ruler of the universe. The history of the temple starts with a legendary story. Lord Brahma and Lord Vishnu got into an argument that broke into a fight about their supremacy. As a moderator, Lord Shiva took a form of light that pierced through three worlds. Shiva demanded them to find the tip of the light and the one who finds the tip of the light would be considered supreme. Vishnu took the form of a pig and dug down to find the tip and Lord Brahma rode up to find the tip. The place where Shiva's light pierced through the earth are the 12 jyoti lingas and this temple is one among those.",
    eventTime: [
      "Mangala Aarti : 3 AM- 4 AM (Morning)",
      "Bhog Aarti : 11.15 AM to 12.20 PM (Day)",
      "Sandhya Aarti : 7 PM to 8.15 PM (Evening)",
      "Shringar Aarti : 9 PM to 10.15 PM (Night)",
      "Shayan Aarti : 10.30 PM to 11 PM (Night)",
    ],
    distance: "1.3km",
  },
  {
    title: "KAL BHAIRAVNATH MANDIR",
    img: [KaalSlide1, KaalSlide2, KaalSlide3],
    details:
      "Kal Bhairavnath Temple is one of the ancient temples ofLord Shiva that can be found in Varanasi. This temple has a strong link with the history and culture of the city. Kal Bhairav Temple is the fiercest form of Lord Shiva. The deity is portrayed as a man wearing garlands made with human skull. It is said that the death itself is afraid of this form of Lord Shiva. It is said Kal Bhairav decides who has to stay in Varanasi and who should not. The deity is portrayed as a man wearing garlands made with human skull. It is said that the death itself is afraid of this form of Lord Shiva. It is said that Kal Bhairav decides who has to stay in Varanasi and who should not. People of Varanasi get permission from the deity before leaving the town for any reason. Anyone visiting Varanasi should visit the temple first and get permission to enter into Varanasi, according to folklore. Lord Vishnu surrendered but Lord Brahma continued to stare at the light, which caused one of his heads to burn out. The light that was created by Lord Shiva became a being called as Kal. Kal walked around the world with the head of Brahma in his hand. When he entered Kashi, the head dropped to the ground and his sins washed away. Kaal stayed back in Varanasi.",
    distance: "450m",
  },
  {
    title: "DASHASHWAMEDH GHAT",
    img: [Dasaswamedh1, Dasaswamedh2, Dasaswamedh3],
    details:
      "Dashashwamedh ghat is famous for great event named Gange Aarti at each evening after sunset. Thousands of earthen lamps are immersed in the Gange water as a floating lamp which looks awesome and seems a great religious ritual of the Hindu devotees.The Gange aarti is a religious event which is performed at the bank of the Gange River by a group of young priests who become draped in a saffron color dress having puja thali and a big brass aarti lamp. There are two mythologies about the ghat is that the Lord Brahma was created the Dashashwamedh ghat in order to welcome the Lord Shiva, and the second one is the Lord Brahma was sacrificed the ten horses in a yajna here. The Dashashwamedh ghat was reconstructed by the Bajirao Pesava I in the year 1740 AD. It was later reconstructed by the Queen of the Indore (the princess Ahilyabai Holkar) in the year 1774. It is also believed that the ten horse sacrifice was done by the Bhara Shiva Naga rulers in the second century. This ghat is famous for the Agni Pooja which is held here daily in the evening by a group of the priest who are dedicated to the Lord Shiva, River Ganges, Surya, Agni as well as the whole universe. Tourists can see the excellent and colorful river front view. A group of sadhus can be seen performing religious activities at this ghat.",
    distance: "1.7km",
  },
  {
    title: "MANIKARNIKA GHAT",
    img: [ManiGhat1, ManiGhat2, ManiGhat3],
    details: `The Importance of Cremation in Hinduism The burning of one's body upon their demise is fundamental in Hinduism, for the soul is purified and freed from the body. The cremation process is crucial in achieving nirvana, and the ritual's many stages must be completed correctly or the soul will not transition to the afterlife. It is one of the oldest ghats in Varanasi. The Manikarnika Ghat is mentioned in a Gupta inscription of 5th century. It is revered in Hindu religion. When Mata Sati (Aadi shakti mata) sacrificed her life & set her body ablaze after Raja Daksh Prajapati (one of the sons of Lord Brahma) tried to humiliate Lord shiva in a Yagya practiced by Daksh. Lord Shiva took her burning body to the Himalaya. On seeing the unending sorrow of Lord shiva, Vishnu sends the Divine chakra to cut the body into 51 parts which fall on earth. They are called "Ekannya Shaktipeeth". Lord Shiva established Shakti Peeth wherever Sati's body had fallen. At Manikarnika ghat, Mata Sati's Ear's ornament had fallen.`,
    distance: "1.5km",
  },
  {
    title: "ASSI GHAT",
    img: [AssiGhat1, AssiGhat2, AssiGhat3],
    details:
      "Assi Ghat is the Southernmost ghat in Varanasi. To most visitors to Varanasi, it is known for being a place where long-term foreign students, researchers and tourists live. Assi Ghat is one of the ghats often visited for recreation and during festivals. On typical days about 300 people visit every hour in the mornings, and on festival days 2500 people arrive per hour. Most of the people visiting the ghat on usual days are students from the nearby Banaras Hindu University. The ghat accommodates about 22,500 people at once during festivals like Shivratri. There are a lot of activities for tourists to engage in at the ghat. Visitors can go for boat rides, go up in a hot-air balloon for an aerial view of Assi ghat, enjoy the daily talent show in the evening or eat at one of the many restaurants and cafes in the area.",
    distance: "4.3km",
  },
  {
    title: "SANKAT MOCHAN (MONKEY TEMPLE)",
    img: [SankatMochan1, SankatMochan2, SankatMochan3],
    details: `Sankat Mochan Hanuman Temple is a Hindu temple in Varanas, Uttar Pradesh, India and is dedicated to the Hindu God Hanuman. The temple was established by famous Hindu preacher and poet saint Sri Goswami Tulsidas in the early 1500's and is situated on the banks of the Assi river. The deity was named "Sankat Mochan" meaning the "reliever from troubles". In the temple, offerings to Lord Hanuman (called Prasad) are sold like the special sweet "besan ke ladoo", which the devotees relish the idol is also decked with a pleasant marigold flower garland as well. This temple has the unique distinction of having Lord Hanuman facing his Lord, Ram, whom he worshipped with steadfast and selfless devotion.`,
    distance: "5.1km",
  },
  {
    title: "THE BANARAS HINDU UNIVERSITY",
    img: [BHU1, BHU2, BHU3],
    details:
      "The Banaras Hindu University was established by Madan Mohan Malviya. A prominent lawyer and an Indian independence activist, Malviya considered education as the primary means for achieving a national awakening. At the 21st Conference of the Indian National Congress in Benares in December 1905, Malviya publicly announced his intent to establish a university in Varanasi. On the request of Malviya, Attar Singh laid the foundation stone of Banaras Hindu University at Varanasi in 1914. Malviya continued to develop his vision for the university with inputs from other Indian nationalists and educationists. He published his plan in 1911. The focus of his arguments was the prevailing poverty in India and the decline in income of Indians compared to Europeans. The plan called for the focus on technology and science, besides the study of India's religion and culture. BHU is located on the southern edge of Varanasi, near the banks of the river Ganges. Development of the main campus, spread over 1,300 acres (5.3 km), started in 1916 on land donated by the then Kashi Naresh Prabhu Narayan Singh. The campus layout approximates a semicircle, with intersecting roads laid out along the radii or in arcs. Buildings built in the first half of the 20th century are fine examples of Indo-Gothic architecture..",
    distance: "6km",
  },
  {
    title: "TULSI MANAS TEMPLE",
    img: [TulsiTemple1, TulsiTemple2, TulsiTemple3],
    details:
      "Tulsi Manas Mandir Temple is one of the famous temples of the Varanasi and also known as the Tulsi Birla Manas Mandir. It is located at Durga Kund very close to the Durga Temple in the Varanasi city. It is made up of the white marble in the year 1964 and looks very attractive having beautiful garden. The temple is dedicated to the Lord Ram and constructed by the family of Banaras. The temple has beautiful images of the Ram, Sita, Lakshman and Hanuman. Other temples located near to the Tulsi Manas Mandir are Durga temple, Sankat Mochan Temple and New Vishwanath temple. It has become the point of the tourist attraction in the Kashi. It is considered that the temple is built at the same place where the famous and great Indian Epic Ramcharitamanasa written by the saint GoswamiTulsidas. The wall of the temple has various scenes from the Ramcharitamanasa..",
    eventTime: [
      "Opening Time of the Temple is: 5:30 am to 12:00 pm and 3:30 pm to 9:00 pm.",
      "Aarti time is: 6:00 am in the morning and 4:00 pm in the evening.",
    ],
    distance: "8km",
  },
  {
    title: "DURGA MATA TEMPLE",
    img: [DurgaTemple1, DurgaTemple2, DurgaTemple3],
    details:
      "Durga Temple, Durga Mandir and Durga Kund Mandir are some of the names for this temple. It is one of the famous temples, which is dedicated to Goddesses Durga. The red coloured temple structure is attracting numerous tourists and plenty of pilgrims every day. The queen of Bengal built the temple in 18th century. The temple has a pond (kund) which is connected to the holy River Ganges. It is said that the deity inside the temple was not made by man but appeared itself. The king of Varanasi invited the princes from surrounding cities for his daughter to choose her groom. Later, he learnt that his daughter was in love with the prince of Varanasi and got them married. The princes who came for the event felt insulted and waged war against the king of Varanasi. The prince of Varanasi prayed to Durga to help the king of Varanasi in the war. It is said that the Goddess fought the war for the king and the temple was built to please Durga and to plead her to protect the land. The exact time of construction of the temple is not known. It was built in 18th century in Nagara style. The temple is painted in dark red colour to represent the power of the Goddess.",
    distance: "6km",
  },
  {
    title: "SARNATH",
    img: [Sarnath1, Sarnath2, Sarnath3],
    details:
      "Located 13 km North East of Varanasi, Sarnath is among the famous Buddhist pilgrimages in India. It is a popular place to visit around Varanasi for those seeking peace and solitude after jostling their way through the frazzled ghats and streets of Kashi. After achieving enlightenment at Bodhgaya, the Buddha came to Sarnath seeking his former companions and thus gave his first sermon here. Popular sightseeing places in Sarnath include the Dhamekh Stupa & Monastery Ruins, the Archaeological Museum, the Chaukhandi Stupa, the Ashoka Pillar, the Mulagandha Kuti Vihar, and the Chinese & Thai Temple and Monastery. Sarnath is a place located 10 kilometres north-east in Varanasi near the confluence of the Ganges and the Varuna rivers in Uttar Pradesh, India. The deer park in Sarnath is where Gautama Buddha first taught the Dhamma, and where the Buddhist Sangha came into existence through the enlightenment of Kondanna.",
    distance: "6.8km",
  },
  {
    title: "RAM NAGAR FORT",
    img: [RamNagarFort1, RamNagarFort2, RamNagarFort3],
    details:
      "The Ramnagar Fort is situated in Ramnagar, Varanasi, India. It has been the home of Kashi Naresh since the eighteenth century. The current king and the resident of the fort is Kashi Naresh Anant Narayan Singh, who is also known as the Maharaja of Varanasi. Even though the royal title has been abolished since 1971, the maharaja is revered by locals to be the re-incarnation of lord Shiva. The sandstone structure was built in the Mughal style of architecture in 1750 by Kashi Naresh Raja Balwant Singh. The fort is at a scenic location on the eastern right bank of the Ganges River, opposite to the Varanasi Ghats. It is 14 kilometres (8.7 mi) from Varanasi and 2 kilometres (1.2 mi) from the Banaras Hindu University.Boat ride to the fort from Dashashwamedh Ghat in Varanasi takes about an hour.",
    distance: "11km",
  },
];

const services = [
  "AC",
  "Hot water",
  "Tea/Coffee Maker",
  "Wifi",
  "Intercom",
  "Television",
  "Study table",
];

const STUDIO_SERVICES = [
  "AC",
  "Hot water",
  "Tea/Coffee Maker",
  "Wifi",
  "Intercom",
  "Television",
];

const SUITE_SERVICES = [
  "Park View",
  "Sofa",
  "Living room",
  "AC",
  "Hot water",
  "Tea/Coffee Maker",
  "Wifi",
  "Intercom",
  "Television",
  "Study table",
];

export const rooms = [
  {
    title: "Suite Room",
    content: SUITE_CONTENT,
    image: SuiteBedRoom,
    services: SUITE_SERVICES,
    key: "suite",
    description:
      "Experience luxury in Kashi with our Suite Room at Hotel Ananda Kashi, Varanasi. Contact us at +91-8957741152. CALL US TO BOOK NOW.",
    img: [
      SuiteRoom,
      SuiteRoom2,
      SuiteRoom3,
      SuiteRoom4,
      // ExecutiveRoom4,
      ExecutiveRoom5,
    ],
  },
  {
    title: "Executive Room",
    content: EXECUTIVE_CONTENT,
    image: ExecutiveBathroom3,
    services: services,
    key: "executive",
    description:
      "Stay in Comfort at Hotel Ananda Kashi, Varanasi with our Executive Room. Contact us at +91-8957741152. CALL US TO BOOK NOW.",
    img: [
      ExecutiveRoom,
      ExecutiveRoom2,
      ExecutiveRoom3,
      // ExecutiveRoom4,
      ExecutiveRoom5,
      ExecutiveRoom6,
    ],
  },
  {
    title: "Studio Room",
    content: STUDIO_CONTENT,
    image: StudioBedRoom,
    services: STUDIO_SERVICES,
    key: "studio",
    description:
      "Enjoy a comfortable stay in our Studio Room at Hotel Ananda Kashi, Varanasi. Contact us at +91-8957741152. CALL US TO BOOK NOW.",
    img: [
      StudioRoom,
      StudioRoom2,
      StudioRoom3,
      StudioRoom4,
      StudioRoom5,
      StudioRoom6,
    ],
  },
];
