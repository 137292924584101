import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false,
  hasShownModal: false,
};

const modalReducer = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    setHasShownModal: (state, action) => {
      state.hasShownModal = action.payload;
    },
  },
});

export const { openModal, closeModal, setHasShownModal } = modalReducer.actions;
export default modalReducer.reducer;
