import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { IoClose } from "react-icons/io5";
import { BOOKING_LINK, ROUTE_CONTACT, rooms } from "../../constants";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const [IsActive, setIsActive] = useState(false);
  useEffect(() => {
    if (isOpen) {
      // Save current scroll position
      const scrollY = window.scrollY;

      // Add styles to body
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
    } else {
      // Get scroll position
      const scrollY = document.body.style.top;

      // Remove styles from body
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Restore scroll position
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }

    // Cleanup function
    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, [isOpen]);
  return (
    <div className={`container-sidebar  ${isOpen ? "active" : "inactive"}`}>
      <div className="sidebar-wrapper">
        <div style={{ padding: "12px" }}>
          <IoClose
            size={"2rem"}
            color="#790316"
            onClick={() => setIsOpen(false)}
          />
        </div>

        <div className="sidebar-menuList-wrap">
          <li className="menuList">
            <a href={"/"} className="menuListAnchor">
              Home
            </a>
          </li>
          <li className="menuList">
            <a href={"/about"} className="menuListAnchor">
              About Us
            </a>
          </li>
          <li className="menuList">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".2rem",
                padding: "12px",
              }}
              onClick={() => setIsActive(!IsActive)}
              className="menuListAnchor"
            >
              Rooms
              <IoMdArrowDropdown size={25} color="#790316" />
            </div>
            {IsActive ? (
              <div className="" onClick={() => setIsActive(false)}>
                {rooms?.map((r, i) => {
                  return (
                    <div
                      className="sidebar-rooms"
                      onClick={() => {
                        navigate(`/room/${r.key}`, { state: r });
                        setIsOpen(false);
                      }}
                      key={i}
                    >
                      {r.title}
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </li>
          <li className="menuList">
            <a href={"/tourists_attractions"} className="menuListAnchor">
              Attractions
            </a>
          </li>
          <li className="menuList">
            <a href={`/${ROUTE_CONTACT}`} className="menuListAnchor">
              Contact
            </a>
          </li>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
