import axios from "axios";

export const sendMessage = async (data) => {
  try {
    const res = await axios.post(
      `https://anandakashi.onrender.com/users`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};
